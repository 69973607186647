import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {})
};
Vue.use(VueRouter)

const routes = [
  {//首页
    path: '/',
    name: 'home',
    component: HomeView
  },
  {//会员风采
    path: '/member',
    name: 'member',
    component: () => import( '../views/member.vue')
  },
  {//公示公告
    path: '/notice',
    name: 'notice',
    component: () => import( '../views/notice.vue')
  },
  {//标准规范
    path: '/standard',
    name: 'standard',
    component: () => import( '../views/standard.vue')
  },
  {//行业交流
    path: '/industry',
    name: 'industry',
    component: () => import( '../views/industry.vue')
  },
  {//专家风采
    path: '/expert',
    name: 'expert',
    component: () => import( '../views/expert.vue')
  },
  {//教育培训
    path: '/train',
    name: 'train',
    component: () => import( '../views/train.vue')
  },
  {//理论研究
    path: '/theory',
    name: 'theory',
    component: () => import( '../views/theory.vue')
  },
  {//合规指数
    path: '/Compliance',
    name: 'Compliance',
    component: () => import( '../views/Compliance.vue')
  },
  {//详情页
    path: '/detail',
    name: 'detail',
    component: () => import( '../views/detail.vue')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 } // 将滚动位置设置为顶部
  }
})

export default router
