<template>
  <div v-title :data-title="dataItem.addressTitle">
    <div class="header">
      <div class='container'>
        <div class="header_top flex items-center just-between">
          <div class='flex items-center'>
            <el-image lazy style="width:96px; height:96px;border-radius:15px;" :src="buseUrl+dataItem.logoUrl" fit="fill"></el-image>
            <span class="title">{{selectedlabel}}{{ dataItem.webTitle }}</span>
          </div>
          <div>
            <!-- <el-select v-model="selectedValue" class="dropdown" clearable placeholder="区域选择" @change="change" size="medium">
              <el-option v-for="item in options" :key="item.id" :label="item.areaName" :value="item.areaCode"></el-option>
            </el-select> -->
            <el-cascader
              v-model="selectedValue"
              :options="options"
              clearable
              ref="cascaderAddr"
              :show-all-levels="false"
              :props="{ expandTrigger: 'hover',value:'areaCode',label:'areaName',emitPath:false, }"
              @change="change">
            </el-cascader>
          </div>
        </div>
        <div class='header_btm flex items-center just-center'>
          <ul class="ui flex items-center just-between cursor">
            <li v-for='(item, index) in list' :key='index' :class="{'active': currentIndex == item}" @click='tabClick(index)'>
              <span>{{item}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="height:210px;"></div>
  </div>
</template>
  
<script>
  import { queryHomepage,queryCityBasicInfo,queryAreaTreeInfo } from '@/api/home'
  export default {
    name: 'BeileihomeHeader',
    props: {
      name: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        buseUrl:process.env.VUE_APP_BACKEND_URL+'/api/file',
        list: ['首页', '会员风采', '公示公告', '标准规范', '行业交流', '专家风采', '教育培训','理论研究',],//'合规指数'
        currentIndex: this.name,
        selectedValue: JSON.parse(localStorage.getItem('area'))?JSON.parse(localStorage.getItem('area')).areaCode:'',
        selectedlabel: JSON.parse(localStorage.getItem('area'))?JSON.parse(localStorage.getItem('area')).areaName:'',
        options: [ // 下拉框的选项，模拟接口返回的值，包含显示的标签和实际的值
          // { areaName: '二七区', areaCode: 1 },
          // { areaName: '中原区', areaCode: 2 },
          // { areaName: '高新区', areaCode: 3 },
          // { areaName: '惠济区', areaCode: 4 },
          // { areaName: '金水区', areaCode: 5 }
        ],
        dataItem:JSON.parse(localStorage.getItem('webSiteData')),
      };
    },
  
    mounted() {
      this.getCityBasicInfo()
    },
  
    methods: {
      getCityBasicInfo(){
        // queryCityBasicInfo().then(res=>{//区域列表
        //   if(res.status==200){
        //     this.options = res.data
        //   }
        // })
        queryAreaTreeInfo().then(res=>{//三级区域列表
          if(res.status == 200){
            this.options = res.data.pro
          }
        })
      },
      getHomepage(area){
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        queryHomepage(area).then(res=>{
          if(res.status==200){
            this.dataItem = res.data.officialWeb
            localStorage.setItem('webSiteData', JSON.stringify(res.data.officialWeb));
            // this.$router.push('/')
            setTimeout(() => {
              loading.close();
              location.reload()
            }, 1000);
          }
        })
      },
      change(e){
        // console.log(e)
        if (this.$refs.cascaderAddr.getCheckedNodes() && this.$refs.cascaderAddr.getCheckedNodes()[0]) {
          const nodeContent = this.$refs.cascaderAddr.getCheckedNodes()[0].data
          console.log(nodeContent )//这个位置可以打印一下
          let area = {areaName:nodeContent.areaName,areaCode:nodeContent.areaCode,}
          this.selectedlabel = area.areaName
          localStorage.setItem('area', JSON.stringify(area));
          this.getHomepage(area)
        }else{
          let area = {areaName:'',areaCode:'',}
          this.selectedlabel = area.areaName
          localStorage.setItem('area', JSON.stringify(area));
          this.getHomepage(area)
        }
      },
      tabClick(index) {
        switch(index) {
          case 0:   //首页
            this.$router.push('/');
            break;
          case 1:   //会员风采
            this.$router.push('/member');
            break;
          case 2:   //公示公告
            this.$router.push('/notice');
            break;
          case 3:  //标准规范
              this.$router.push('/standard')
              break;
          case 4:   //行业交流
            this.$router.push('/industry');
            break;
          case 5:   //专家风采
            this.$router.push('/expert');
            break;
          case 6: //教育培训
            this.$router.push('/train');
            break;
          case 7: //理论研究
            this.$router.push('/theory');
            break;
          case 8: //合规指数
            this.$router.push('/Compliance');
            break;
        }
      },
    },
  };
</script>
  
<style lang="scss">
.header{
  width:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:99;
  .container{
    background:url('@/assets/headerBg.png') no-repeat;
    background-size:100% 100%;
    width:100%;
    height:150px;
    .header_top{
      height:150px;
      width:1200px;
      margin:0 auto;
      .title{
        font-size: 36px;
        color: #FFFFFF;
        margin-left:30px;
      }
      .dropdown{
        width:160px;
        font-size:30px;
        border-radius: 8px;
        border:none;
      }
    }
  }
  .header_btm{
    width:100%;
    height: 57px;
    background: #EBECF1;
    .ui{
      list-style-type: none;
      li{
        font-size:20px;
        padding:5px 20px;
        flex-shrink: 0;
      }
      .active{
        background:#0c4abe;
        color:#fff;
      }
    }
  }
}
</style>