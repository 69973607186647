import request from '@/utils/request'

// 官网配置
export function queryHomepage(data) {
  return request({
    url: '/api/homepage/homepage/queryHomepage',
    method: 'post',
    data
  })
}

// 在线咨询
export function addMenuAdvice(data) {
  return request({
    url: '/api/homepage/menuadvice/addMenuAdvice',
    method: 'post',
    data
  })
}

// 获取区域列表
export function queryCityBasicInfo(data) {
  return request({
    url: '/api/homepage/areaInfo/queryCityBasicInfo',
    method: 'get',
    params:data
  })
}

// 获取三级区域列表
export function queryAreaTreeInfo(data) {
  return request({
    url: '/api/homepage/areaInfo/queryAreaTreeInfo',
    method: 'get',
    params:data
  })
}


// 轮播图
export function queryPromotion(data) {
  return request({
    url: '/api/homepage/homepage/queryPromotion',
    method: 'post',
    data
  })
}
// 最新活动
export function queryActivity(data) {
  return request({
    url: '/api/homepage/homepage/queryActivity',
    method: 'post',
    data
  })
}
// 新闻动态
export function queryNews(data) {
  return request({
    url: '/api/homepage/homepage/queryNews',
    method: 'post',
    data
  })
}
// 宣传片
export function queryVedio(data) {
  return request({
    url: '/api/homepage/homepage/queryVedio',
    method: 'post',
    data
  })
}
// 理论成果
export function queryTheoryManager(data) {
  return request({
    url: '/api/homepage/homepage/queryTheoryManager',
    method: 'post',
    data
  })
}
//教育培训
export function queryEducation(data) {
  return request({
    url: '/api/homepage/homepage/queryEducation',
    method: 'post',
    data
  })
}
//会员风采列表
export function queryMember(data) {
  return request({
    url: '/api/homepage/homepage/queryMember',
    method: 'post',
    data
  })
}
//首页会员风采
export function queryMemberGroup(data) {
  return request({
    url: '/api/homepage/homepage/queryMemberGroup',
    method: 'post',
    data
  })
}
//公开课
export function queryPublicClass(data) {
  return request({
    url: '/api/homepage/homepage/queryPublicClass',
    method: 'post',
    data
  })
}
// 获取菜单类别
export function queryCategoryList(query) {
  return request({
    url: '/api/homepage/menuCategory/queryCategoryList',
    method: 'get',
    params: query
  })
}
//公告公示列表
export function getAffiche(data) {
  return request({
    url: '/api/homepage/homepage/getAffiche',
    method: 'post',
    data
  })
}
//标准规范列表
export function getStandardManager(data) {
  return request({
    url: '/api/homepage/homepage/getStandardManager',
    method: 'post',
    data
  })
}
//行业交流列表
export function getIndustryInterflow(data) {
  return request({
    url: '/api/homepage/homepage/getIndustryInterflow',
    method: 'post',
    data
  })
}
//专家列表
export function queryMenuExpertManager(data) {
  return request({
    url: '/api/homepage/homepage/queryMenuExpertManager',
    method: 'post',
    data
  })
}
//教育列表
export function getEducation(data) {
  return request({
    url: '/api/homepage/homepage/getEducation',
    method: 'post',
    data
  })
}
//理论研究列表
export function getTheoryManager(data) {
  return request({
    url: '/api/homepage/homepage/getTheoryManager',
    method: 'post',
    data
  })
}


//公示公告详情
export function queryAfficheDetailById(data) {
  return request({
    url: '/api/homepage/homepage/article/queryAfficheDetailById',
    method: 'post',
    data
  })
}

//教育培训详情
export function queryEducationDetailById(data) {
  return request({
    url: '/api/homepage/homepage/article/queryEducationDetailById',
    method: 'post',
    data
  })
}

//行业交流详情
export function queryIndustryInterflowDetailById(data) {
  return request({
    url: '/api/homepage/homepage/article/queryIndustryInterflowDetailById',
    method: 'post',
    data
  })
}

//标准规范详情
export function queryStandardDetailById(data) {
  return request({
    url: '/api/homepage/homepage/article/queryStandardDetailById',
    method: 'post',
    data
  })
}

//理论研究详情
export function queryTheoryDetailById(data) {
  return request({
    url: '/api/homepage/homepage/article/queryTheoryDetailById',
    method: 'post',
    data
  })
}

//专家详情
export function queryExpertDetailById(data) {
  return request({
    url: '/api/homepage/homepage/article/queryExpertDetailById',
    method: 'post',
    data
  })
}

//会员文章详情
export function queryMemberDetailById(data) {
  return request({
    url: '/api/homepage/homepage/article/queryMemberDetailById',
    method: 'post',
    data
  })
}