<template>
    <div>
      <ul class='nav'>
        <li v-for='(item, index) in list' :key='index' @click='TabNavClick(index)' @mouseover="mouseOver(index)" @mouseout="mouseOut(index)">
          <img class="icon" :src="item.img" alt="">
          <div class="title">{{ item.title }}</div>
          <div class="ewm" v-if="index==ewmIndex==1">
            <div>扫一扫下方二维码</div>
            <div>为您提供专属顾问</div>
            <el-image lazy style="width:131px;height:131px;margin-top:12px;" :src="buseUrl+dataItem.consultantCode" fit="fill"></el-image>
          </div>
          <div class="Hotline flex items-center" v-if="index==phoneIndex==1">
            <i class="el-icon-phone" style="font-size:28px;color:#000;"></i>
            <div>{{dataItem.telephone}}</div>
          </div>
        </li>
      </ul>

      <el-dialog :title="title" :visible.sync="dialogVisible" width="950px" :before-close="handleClose" :close-on-click-modal="false" :modal="true">
        <el-form ref="ruleForm" :model="form" :rules="rules" label-width="0px">
          <el-form-item label="" prop="name">
            <el-input v-model="form.name" placeholder="姓名（必填）"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入手机号（必填）"></el-input>
          </el-form-item>
          <el-form-item label="" prop="identity">
            <el-input v-model="form.identity" placeholder="您的身份（企业/服务商/专家/其他）"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="form.company" placeholder="公司名称（选填）"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input v-model="form.intro" placeholder="需求描述（选填）"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="background:#002EA6;width:100%;" @click="onSubmit('ruleForm')">提交</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

    </div>
  </template>
  
  <script>
  import { addMenuAdvice } from '@/api/home'
  export default {
    name: 'BeileihomeNav',
  
    data() {
      return {
        list: [
            {img:require('@/assets/t1.png'),title:'在线咨询',},
            {img:require('@/assets/t2.png'),title:'专属顾问',},
            {img:require('@/assets/t3.png'),title:'热线电话',},
            {img:require('@/assets/t4.png'),title:'申请试用',},
            {img:require('@/assets/t5.png'),title:'后台管理',},
        ],
        form:{
          name:'',
          phone:'',
          identity:'',
          company:'',
          intro:'',
        },
        title:'',
        dialogVisible:false,
        ewmIndex:-1,
        phoneIndex:-1,
        buseUrl:process.env.VUE_APP_BACKEND_URL+'/api/file',
        dataItem:JSON.parse(localStorage.getItem('webSiteData')),
        rules: {
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' },],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern:/^1[3456789]\d{9}$/, message: "请输入正确的手机号",trigger: "blur",},
          ],
          identity: [{ required: true, message: '请输入身份', trigger: 'blur' },],
        }
      }
    },
  
    mounted() {
      
    },
  
    methods: {
      onSubmit(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            addMenuAdvice(this.form).then(res=>{
              if(res.status == 200){
                this.$message({
                  message: '提交成功！',
                  type: 'success'
                });
                this.dialogVisible = false
              }
            })
          }
        });
      },
      TabNavClick(i) {
        if(i==0){
          // console.log('在线咨询')
          this.title = '立即咨询'
          this.dialogVisible = true
        }else if(i==3){
          // console.log('申请试用')
          this.title = '申请试用'
          this.dialogVisible = true
        }else if(i==4){
            // console.log('后台管理')
            let url = 'https://checkup.zzbeilei.com/'
            window.open(url,'_blank')
        }
      },
      mouseOver(i) {
        if(i==1){
            // console.log('专属顾问')
            this.ewmIndex = i
        }else if(i==2){
            // console.log('热线电话')
            this.phoneIndex = i
        }
      },
      mouseOut(i) {
        this.ewmIndex = -1
        this.phoneIndex = -1
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .nav {
    position: fixed;
    bottom: 15%;
    right:0px;
    display: flex;
    flex-direction: column;
    transition: all .5s ease;
    width:99px;
    z-index: 9;
    > li {
        width:99px;
        height:99px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #002EA6;
        position:relative;
        cursor: pointer;
        color:#fff;
        font-size:18px;
        > .icon {
            margin-bottom:10px;
            width:30px;
            height: auto;
        }
        .ewm{
            width: 200px;
            height: 238px;
            background: #FFFFFF;
            border: 1px solid #DCDCDC;
            border-radius: 5px;
            position:absolute;
            left:-205px;
            top:-10px;
            color:#000;
            font-size:18px;
            text-align:center;
            line-height: 30px;
            padding-top:12px;
        }
        .Hotline{
            width: 170px;
            height: 75px;
            background: #FFFFFF;
            border: 1px solid #DCDCDC;
            border-radius: 5px;
            position:absolute;
            left:-175px;
            top:5px;
            padding:0 8px;
            color:#000;
        }
    }
  }
  </style>